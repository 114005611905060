import {
  ChangeDetectionStrategy,
  Component,
  inject,
  NgZone,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import * as allIcons from 'ionicons/icons';
import { pipe } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { AuthService } from './core/auth/auth.service';
import { FcmService } from './core/services/fcm.service';
import { UserService } from './core/services/user.service';

register();

@Component({
  selector: 'app-root',
  template: `
    <ion-app style="background: transparent;">
      <ion-router-outlet></ion-router-outlet>
    </ion-app>
  `,
  imports: [IonApp, IonRouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #auth = inject(AuthService);
  private readonly ngZone = inject(NgZone);
  private readonly router = inject(Router);
  private readonly fcm = inject(FcmService);

  private readonly userService = inject(UserService);

  constructor() {
    addIcons(allIcons);
    this.#activatedRoute.queryParams
      .pipe(takeUntilDestroyed())
      .subscribe((params) => {
        const ref = params['ref'];
        if (!ref) return;
        this.#auth.ref.set(ref);
      });
  }

  ngOnInit() {
    this.initializeApp();
  }

  initializeApp() {
    // Handle deeplink
    App.addListener('appUrlOpen', (event) => {
      this.ngZone.run(() => {
        const url = new URL(event.url);
        if (url.pathname) {
          this.router.navigate([url.pathname], {
            queryParams: {
              ref: url.searchParams.get('ref') ?? '',
            },
          });
        }
      });
    });

    this.fcm.initPush();
  }
}
