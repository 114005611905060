import { FirebaseOptions, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';

export const fireConfig = (options: FirebaseOptions) => {
  return [
    provideFirebaseApp(() => initializeApp(options)),
    provideRemoteConfig(() => getRemoteConfig()),
    provideAuth(() => getAuth())
  ];
};
