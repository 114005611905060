import { AuthenticationService } from '@absdepot/data/authentication';
import { inject, Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Platform } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';
import { FcmService } from './core/services/fcm.service';
import { WebSyncService } from './mirror/web-sync.service';
import { MapsService } from './shared/services/maps.service';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  readonly #auth = inject(AuthenticationService);
  readonly #fcm = inject(FcmService);
  readonly #maps = inject(MapsService);
  readonly #platform = inject(Platform);
  readonly #router = inject(Router);
  readonly #swUpdate = inject(SwUpdate);
  readonly #webSync = inject(WebSyncService);
  readonly #zone = inject(NgZone);

  constructor() {
    // if (this.#swUpdate.isEnabled) {
    //   interval(60 * 15 * 1000)
    //     .pipe(takeUntilDestroyed())
    //     .subscribe(() => this.#swUpdate.checkForUpdate());
    //   this.#swUpdate.versionUpdates.subscribe(() => {
    //     console.log('Version updated');
    //     this.#swUpdate.activateUpdate().then(() => location.reload());
    //   });
    // }
  }

  async boot() {
    await this.#platform.ready();
    await this.#webSync.checkAndUpdate();

    this.#auth.boot();
    this.#fcm.boot();
    this.#maps.bootLibraries('drawing', 'geometry');
    this.bootIcons();
    this.registerListeners();
  }

  protected bootIcons() {
    addIcons(icons);
  }

  protected registerListeners() {
    App.addListener('appUrlOpen', this.urlOpenEventHandler);
    App.addListener('appStateChange', this.stateChangedHandler);
  }

  private urlOpenEventHandler = (event: URLOpenListenerEvent) => {
    this.#zone.run(() => {
      const url = new URL(event.url);
      if (url.pathname) {
        this.#router.navigate([url.pathname], {
          queryParams: {
            ref: url.searchParams.get('ref') ?? '',
          },
        });
      }
    });
  };

  private stateChangedHandler = (event: AppState) => {
    if (event.isActive) {
      this.#webSync.checkAndUpdate();
    }
  };
}
