import { inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private readonly platform = inject(Platform);

  async initialize() {
    await this.platform.ready();
    await google.maps.importLibrary('drawing');
    await google.maps.importLibrary('geometry');
  }
}
