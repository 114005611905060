import { User } from '@sentry/angular';
import { Logger } from './logger.service';
import * as Sentry from '@sentry/angular';

export class SentryLogger extends Logger {
  setUser(user: User | null | undefined): void {
    Sentry.setUser({ email: user?.email, id: user?.id })
  }

}
